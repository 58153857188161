import BioSelect from "./BioSelect";
import Work from "./Work";
import Skills from "./Skills";

export default function About() {
  return (
    <div id="about">
      <BioSelect />
      <Work />
      <Skills />
    </div>
  );
}
