export default function ProjectLink() {
  return (
    <a
      className="see-more-blogs"
      href="https://github.com/jamesncox"
      rel="noopener noreferrer"
      target="_blank"
    >
      <button className="see-more-button">More Projects</button>
    </a>
  );
}
